import { useFormContext } from 'react-hook-form';
import styles from './ModalInput.module.scss';
import { type FormControlProps } from '@/components/Form';
import { get } from 'lodash';
import { type HookFormRules } from '@/models/HookFormRules';

interface Props extends FormControlProps {
  name: string;
  rules?: HookFormRules;
}

export const ModalInput = (props: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { name, size, rules, ...rest } = props;
  const error = (get(errors, name)?.message as string) ?? '';

  return (
    <>
      <input className={styles.input} {...rest} {...register(name, rules)} />
      {!!error && (
        <div className={styles.inputError}>
          <small>{error}</small>
        </div>
      )}
    </>
  );
};
