import type { Ref } from 'react';
import React from 'react';

import { Select } from '../Select/Select';
import { type AsyncSelectProps } from './AsyncSelectProps';
import { useAsyncSelect } from '@/components/Form/AsyncSelect/useAsyncSelect';
import { Controller, useFormContext } from 'react-hook-form';
import { InputWithErrorTooltip } from '../InputWithErrorTooltip';
import { get } from 'lodash';
import { type HookFormRules } from '@/models/HookFormRules';

export const AsyncSelect = React.forwardRef((props: AsyncSelectProps, ref: Ref<any>) => {
  const asyncSelect = useAsyncSelect(props);
  return (
    <div>
      <Select {...asyncSelect} />
    </div>
  );
});

AsyncSelect.displayName = 'AsyncSelect2';

interface AsyncSelectConnectedProps extends Omit<AsyncSelectProps, 'onChange' | 'selected'> {
  rules?: HookFormRules;
  name: string;
  onChange?: (value: number, data: any) => void;
}

export const AsyncSelectConnected = (props: AsyncSelectConnectedProps, ref: Ref<any>) => {
  const { name, rules, ...rest } = props;
  const {
    formState: { errors },
    // clearErrors,
  } = useFormContext();

  return (
    <div>
      <Controller
        name={name}
        rules={rules}
        render={({ field: { onChange, value } }) => {
          const errorMessage = (get(errors, name)?.message ?? '') as string;
          return (
            <InputWithErrorTooltip tooltipText={errorMessage} isInvalid={!!errorMessage}>
              <AsyncSelect
                {...rest}
                onChange={(...args) => {
                  onChange(...args);
                  props.onChange?.(...args);
                }}
                defaultValue={value}
                isInvalid={!!errorMessage}
              />
            </InputWithErrorTooltip>
          );
        }}
      />
    </div>
  );
};
