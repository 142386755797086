import BootstrapForm from 'react-bootstrap/Form';
import { type FormControlProps as BootstrapFormControlProps } from 'react-bootstrap/FormControl';
import BootstrapFormCheckInput, {
  type FormCheckInputProps as BootstrapFormCheckInputProps,
} from 'react-bootstrap/FormCheckInput';
import { useEffect, useRef } from 'react';

import './Form.scss';

export const Form = BootstrapForm;

export interface FormCheckProps extends BootstrapFormCheckInputProps {
  indeterminate?: boolean;
}

export type FormControlProps = BootstrapFormControlProps;

export const FormCheckInput = (props: FormCheckProps) => {
  const { indeterminate, ...restProps } = props;
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.indeterminate = !!indeterminate;
  }, [indeterminate]);
  return <BootstrapFormCheckInput {...restProps} ref={ref} />;
};
